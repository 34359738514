import React from "react";
import Particles from 'react-particles-js';
import particleConfig from "../utils/particles.json"

export default function ComminSoon() {
  return (
      <div className="wrap">
        {/* <div className="background"></div> */}
        <Particles className="particle-container" params={particleConfig}
              style={{
                width: '100%',
                height: '100%' 
              }}
            />
        <div className="content text-flicker-in-glow">
          <h2>I'm Konstantinos Tsolakidis</h2>
          <h3>Software Engineering Freelancer</h3>
          <h3>contact @ <span className="email"> konstantinostsolakidis@gmail.com</span></h3>
        </div>
      </div>
  );
}
